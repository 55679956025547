import { Action, Reducer } from "redux";
import { RequestState } from "../sharedTypes";
import { KnownAction } from "./actions";
import SendPushNotificationsState from "./state";

const unloadedState: SendPushNotificationsState = {
    sendRequestState: RequestState.NotStarted,
    testRequestState: RequestState.NotStarted
};

export const reducer: Reducer<SendPushNotificationsState> = (state: SendPushNotificationsState | undefined, incomingAction: Action): SendPushNotificationsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_SEND_OPERATIONAL_PUSH_NOTIFICATION":
            return {
                ...state,
                sendRequestState: RequestState.InProgress
            };
        case "RECEIVE_SEND_OPERATIONAL_PUSH_NOTIFICATION":
            return {
                ...state,
                sendRequestState: RequestState.Succeeded
            };
        case "REJECT_SEND_OPERATIONAL_PUSH_NOTIFICATION":
            return {
                ...state,
                sendRequestState: RequestState.Failed
            };
        case "CLEAR_SEND_OPERATIONAL_PUSH_NOTIFICATION":
            return {
                ...state,
                sendRequestState: RequestState.NotStarted
            };
        case "REQUEST_TEST_CUSTOM_PUSH_NOTIFICATION":
            return {
                ...state,
                testRequestState: RequestState.InProgress
            };
        case "RECEIVE_TEST_CUSTOM_PUSH_NOTIFICATION":
            return {
                ...state,
                testRequestState: RequestState.Succeeded
            };
        case "REJECT_TEST_CUSTOM_PUSH_NOTIFICATION":
            return {
                ...state,
                testRequestState: RequestState.Failed,
                testErrorMessage: action.errorMessage
            };
        case "CLEAR_TEST_CUSTOM_PUSH_NOTIFICATION":
            return {
                ...state,
                testRequestState: RequestState.NotStarted
            };
        default:
            return state;
    }
};
