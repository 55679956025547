import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState, IAuthProps, RequestState } from "../../store";
import { withAuthProps, withCommonProps, Page, Spinner, ConfirmAlert } from "../Common/";
import { actionCreators } from "../../store/PushNotifications/actionCreators";
import { CommonState } from "../../store/Common/state";
import { SendPushNotificationsState, CustomTopicNotification, CustomUserNotification } from "../../store/PushNotifications/state";
import { FaExclamationTriangle, FaInfoCircle } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";

type SendPushNotificationsPageProps =
    CommonState &
    SendPushNotificationsState &
    typeof actionCreators &
    IAuthProps;

type Languages = {
    english: string;
    spanish: string;
    french: string;
}

type PredfinedMessage = Languages & {
    name: string;
}

const predefinedMessages: PredfinedMessage[] = [
    {
        name: "Scheduled maintenance",
        english: "A scheduled maintenance will start shortly, and will be available again within the next two hours.",
        spanish: "En breve comenzará un mantenimiento programado y estará disponible nuevamente dentro de las próximas dos horas.",
        french: "Une maintenance programmée démarrera prochainement et sera à nouveau disponible dans les deux prochaines heures.",
    },
    {
        name: "High system load",
        english: "We are currently experiencing high loads on the system, so you can expect longer response times when controlling your thermostat.",
        spanish: "Actualmente estamos experimentando cargas altas en el sistema, por lo que puede esperar tiempos de respuesta más prolongados al controlar su termostato.",
        french: "Nous sommes actuellement confrontés à des charges élevées sur le système, vous pouvez vous attendre à des délais de réponse plus longs lorsque vous contrôlez votre thermostat.",
    },
    {
        name: "Custom message",
        english: "",
        spanish: "",
        french: "",
    },
];

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
const isFalsyOrWhitespace = (s: string) => !s || !s.trim();

const SendPushNotificationsPage = (props: SendPushNotificationsPageProps) => {
    const [predefinedMessage, setPredefinedMessage] = React.useState<PredfinedMessage>(predefinedMessages[0]);
    const [texts, setTexts] = React.useState<Languages>(predefinedMessage);
    const [currentTestLanguage, setCurrentTestLanguage] = React.useState<string>();
    const isLoading = props.sendRequestState === RequestState.InProgress || props.testRequestState === RequestState.InProgress;

    React.useEffect(() => {
        props.clearRequestStates();
    }, [predefinedMessage]);

    const sendPushNotification = () => {
        const notifications: CustomTopicNotification[] = [
            { language: "en", body: texts.english },
            { language: "es", body: isFalsyOrWhitespace(texts.spanish) ? texts.english : texts.spanish },
            { language: "fr", body: isFalsyOrWhitespace(texts.french) ? texts.english : texts.french },
        ];
        props.sendOperationalPushMessage(notifications);
    };

    const testPushNotification = (recipient: string, message: string) => {
        const notification: CustomUserNotification = {
            body: message,
            recipientEmail: recipient.includes("@") ? recipient : undefined,
            recipientUsername: recipient.includes("@") ? undefined : recipient,
        };
        props.testCustomPushNotification(notification);
    };

    return (
        <Page title="Send Push Notifications">
            <h5>Operational status notifications</h5>

            <div className="bg-light" style={{ maxWidth: "800px" }}>
                <ul className="nav nav-tabs">
                    {predefinedMessages.map(message => <li className="nav-item" key={message.name}>
                        <button
                            className={`btn nav-link ${predefinedMessage.name === message.name ? "active shadow-none" : ""}`}
                            onClick={() => {
                                setPredefinedMessage(message);
                                setTexts(message);
                            }}>
                            {message.name}
                        </button>
                    </li>)}
                </ul>

                <div className="border-left border-bottom border-right rounded-bottom p-3">
                    {Object.keys(texts).filter(key => key !== "name").map(key => (
                        <div className="form-group" key={key}>
                            <label>
                                <b>{capitalize(key)}</b>
                            </label>
                            <div className="mb-2">
                                {isFalsyOrWhitespace(texts[key as keyof Languages]) && key === "english" && <span className="text-danger">
                                    <FaExclamationTriangle className="mt-n1" />
                                    &nbsp;This field is required
                                </span>}

                                {isFalsyOrWhitespace(texts[key as keyof Languages]) && key !== "english" && <span className="text-info">
                                    <FaInfoCircle className="mt-n1" />
                                    &nbsp;Defaulting to English
                                </span>}
                            </div>
                            <textarea
                                className="form-control"
                                rows={3}
                                value={texts[key as keyof Languages]}
                                placeholder="Enter push notification text..."
                                onChange={e => setTexts({
                                    ...texts,
                                    [key]: e.target.value
                                })} />
                            <button
                                disabled={isLoading || isFalsyOrWhitespace(texts[key as keyof Languages])}
                                onClick={() => {
                                    confirmAlert({
                                        customUI: ({ onClose }) => <ConfirmAlert
                                            title="Send test notification"
                                            description="This action will send a test push notification to the specified recipient."
                                            onCancel={onClose}
                                            onConfirm={recipient => {
                                                testPushNotification(recipient, texts[key as keyof Languages]);
                                                setCurrentTestLanguage(key);
                                                onClose();
                                            }}
                                            prompt={{
                                                text: props.user?.profile?.email || "",
                                                label: "Recipient username or email address",
                                                placeholder: "Enter recipient username or email address"
                                            }}
                                            confirmText="Send notification"
                                            cancelText="Cancel" />
                                    });
                                }}
                                className="btn btn-sm btn-link m-0 p-0">Send test notification</button>

                            {currentTestLanguage === key && props.testRequestState === RequestState.InProgress && <Spinner small align="left" />}
                            {currentTestLanguage === key && props.testRequestState === RequestState.Failed && <div className="text-danger">Failed to send test notification. {props.testErrorMessage}</div>}
                            {currentTestLanguage === key && props.testRequestState === RequestState.Succeeded && <div className="text-success">Test notification sent successfully.</div>}
                        </div>
                    ))}

                    <button
                        onClick={() => {
                            confirmAlert({
                                customUI: ({ onClose }) => <ConfirmAlert
                                    title="Are you sure?"
                                    description="This action will send a push notification to all users. Are you sure you want to proceed?"
                                    onCancel={onClose}
                                    onConfirm={() => {
                                        sendPushNotification();
                                        onClose();
                                    }}
                                    confirmText="Yes, I am sure"
                                    cancelText="Cancel" />
                            });
                        }}
                        disabled={isLoading || isFalsyOrWhitespace(texts.english)}
                        className="btn btn-primary">Send to all users</button>

                    <button
                        onClick={() => setTexts(predefinedMessage)}
                        className="btn btn-outline-primary ml-3">Reset text</button>

                    {props.sendRequestState === RequestState.InProgress && <Spinner align="left" />}
                    {props.sendRequestState === RequestState.Failed && <div className="text-danger mt-3">Failed to send push notification.</div>}
                    {props.sendRequestState === RequestState.Succeeded && <div className="text-success mt-3">Push notification sent successfully.</div>}
                </div>

            </div>
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => state.pushNotifications,
    actionCreators
)(SendPushNotificationsPage as any)));
