import * as React from "react";

interface SpinnerProps {
    description?: string;
    align?: "left" | "center" | "right";
    small?: boolean;
}

export const Spinner = (props: SpinnerProps) => {
    const align = props.align || "center";

    return (
        <div className={`text-${align} ${props.small ? "" : "my-3"}`}>
            <div className={`${props.small ? "spinner-border-sm" : ""} spinner-border text-primary`} role="status">
                <span className="sr-only">Loading...</span>
            </div>
            {!props.description ? null : <p>{props.description}</p>}
        </div>
    );
};
