import { User } from "oidc-client";
import { UserState } from "redux-oidc";

export interface IAuthProps {
    readonly isAuthenticated: boolean,
    readonly user: User | null,
    readonly roles: string[],
    isInRole: (role: string) => boolean;
    userInteractionIsValid: (role: string | undefined) => boolean;
    readonly isAdmin: boolean;
    readonly isOemAdmin: boolean;
    readonly isSupporter: boolean;
    readonly isUser: boolean;
    readonly userPrivateLabelId: string;
}

export enum KnownRoles {
    Administrator = "Administrator",
    OemAdministrator = "OemAdministrator",
    Supporter = "Supporter",
    User = "User" // eslint-disable-line no-shadow
}

class AuthenticatedComponentProps implements IAuthProps {
    constructor(userState: UserState | undefined) {
        this.isAuthenticated = false;
        const user = !!userState ? userState.user : null;
        this.user = !!user ? user : null;
        this.roles = [];
        if (!!user) {
            this.isAuthenticated = !user.expired;
            if (this.isAuthenticated) {
                const profile = user!.profile;
                const roleClaim = profile.role;
                if (typeof roleClaim === "string" || roleClaim instanceof String) {
                    this.roles.push(roleClaim as string);
                } else if (Array.isArray(roleClaim)) {
                    this.roles = [...this.roles, ...roleClaim];
                }
            }
        }

        this.isAdmin = this.isInRole(KnownRoles.Administrator);
        this.isOemAdmin = this.isInRole(KnownRoles.OemAdministrator);
        this.isSupporter = this.isInRole(KnownRoles.Supporter);
        this.isUser = this.isInRole(KnownRoles.User);
        this.userPrivateLabelId = this.getPrivateLabelId();
    }

    isInRole = (role: string): boolean => {
        if (!role) {
            return false;
        }

        const foundRole = this.roles
            .find(key => key.toUpperCase() === role.toUpperCase());

        return foundRole !== undefined;
    }

    userInteractionIsValid = (role: string | undefined): boolean => {
        if (!role) {
            return false;
        }

        if (this.isAdmin) {
            return true;
        }

        if (this.isOemAdmin && role.toLowerCase() !== KnownRoles.Administrator.toLowerCase()) {
            return true;
        }

        if (this.isSupporter && role.toLowerCase() !== KnownRoles.Administrator.toLowerCase() && role.toLowerCase() !== KnownRoles.OemAdministrator.toLowerCase()) {
            return true;
        }

        return false;
    }

    getPrivateLabelId = (): string => {
        if (!!this.user && !!this.user.profile) {
            const privateLabelId = this.user.profile.pl_id;
            if (!!privateLabelId) {
                return privateLabelId;
            }
        }

        return "";
    }

    readonly isAuthenticated: boolean;
    readonly isAdmin: boolean;
    readonly isOemAdmin: boolean;
    readonly isSupporter: boolean;
    readonly isUser: boolean;
    readonly roles: string[];
    readonly user: User | null;
    readonly userPrivateLabelId: string;

}

export function extractAuthProps(userState: UserState | undefined): IAuthProps {
    return new AuthenticatedComponentProps(userState);
}
