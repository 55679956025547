import { callApiGateway, ApiError } from "../../ApiGateway";
import { AppThunkAction } from "../index";
import { KnownAction } from "./actions";
import { CustomTopicNotification, CustomUserNotification } from "./state";

export const actionCreators = {
    /**
     * Clear request states
     */
    clearRequestStates: (): AppThunkAction<KnownAction> => (dispatch, _) => {
        dispatch({ type: "CLEAR_SEND_OPERATIONAL_PUSH_NOTIFICATION" });
        dispatch({ type: "CLEAR_TEST_CUSTOM_PUSH_NOTIFICATION" });
    },

    /**
     * Send operational push message.
     */
    sendOperationalPushMessage: (notifications: CustomTopicNotification[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_SEND_OPERATIONAL_PUSH_NOTIFICATION" });
        callApiGateway<{ notifications: CustomTopicNotification[] }, {}>("/push-notifications/operational-status-updated/send", "POST", { notifications })
            .then(_ => dispatch({ type: "RECEIVE_SEND_OPERATIONAL_PUSH_NOTIFICATION" }))
            .catch(() => dispatch({ type: "REJECT_SEND_OPERATIONAL_PUSH_NOTIFICATION" }));
    },

    /**
     * Test custom push notification.
     */
    testCustomPushNotification: (notification: CustomUserNotification): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_TEST_CUSTOM_PUSH_NOTIFICATION" });
        callApiGateway<CustomUserNotification, {}>("/push-notifications/operational-status-updated/test", "POST", notification)
            .then(_ => dispatch({ type: "RECEIVE_TEST_CUSTOM_PUSH_NOTIFICATION" }))
            .catch(reason => {
                let errorMessage = String(reason);

                if (reason instanceof ApiError) {
                    errorMessage = reason.status?.message || `HTTP error: ${reason.response.status}`;
                }

                dispatch({
                    type: "REJECT_TEST_CUSTOM_PUSH_NOTIFICATION",
                    errorMessage: errorMessage
                });
            });
    }
};
