import * as React from "react";
import { IconContext } from "react-icons";
import { FaDownload, FaHeartbeat, FaListAlt, FaRegQuestionCircle, FaEnvelopeOpenText, FaSitemap, FaUsersCog, FaStar, FaMedal } from "react-icons/fa";
import { connect } from "react-redux";
import { NavLink as RouterNavLink } from "react-router-dom";
import { ApplicationState } from "../store";
import { extractAuthProps, IAuthProps } from "../store/authTypes";
import "./SideMenu.css";

interface SideMenuButttonProps {
    to: string;
    title: string;
    children?: React.ReactNode;
    exact?: boolean;
    last?: boolean;
}

const SideMenuButton = (props: SideMenuButttonProps) => {
    return (
        <>
            <RouterNavLink
                exact={props.exact}
                className="p-2 d-block text-center text-decoration-none"
                title={props.title}
                to={props.to}>
                <div className="mx-auto">
                    {props.children}
                    <small className="mb-0 d-block">{props.title}</small>
                </div>
            </RouterNavLink>
            {!props.last ? <hr className="m-2" /> : null}
        </>
    );
};

const SideMenu = (props: IAuthProps & { hasMoreThanOneDistributor: boolean }) => {
    return (
        <IconContext.Provider value={{ className: "side-bar-react-icons" }}>
            <nav className="bg-white rounded-lg shadow py-3">
                {props.isAuthenticated && <>
                    <SideMenuButton to="/users" title="User Management"><FaUsersCog /></SideMenuButton>
                    <SideMenuButton to="/thermostats" title="Thermostats"><FaListAlt /></SideMenuButton>
                    {props.isAdmin && <SideMenuButton to="/firmware" title="Firmware"><FaDownload /></SideMenuButton>}
                    {(props.isAdmin || props.isOemAdmin) && <SideMenuButton to="/privateLabels" title="Private Labels"><FaStar /></SideMenuButton>}
                    {(props.isAdmin || props.isOemAdmin || props.hasMoreThanOneDistributor) && <SideMenuButton to="/distributors" title="Distributors"><FaMedal /></SideMenuButton>}
                    <SideMenuButton to="/email-templates" title="Email Templates"><FaEnvelopeOpenText /></SideMenuButton>
                    <SideMenuButton to="/connectivity" title="Connectivity"><FaSitemap /></SideMenuButton>
                </>}

                <SideMenuButton to="/faq" title="FAQ"><FaRegQuestionCircle /></SideMenuButton>
                <SideMenuButton to="/system-health" title="System Health" last><FaHeartbeat /></SideMenuButton>
            </nav>
        </IconContext.Provider>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return ({
        ...extractAuthProps(state.oidc),
        hasMoreThanOneDistributor: !!state.common?.distributors && state.common?.distributors.length > 1
    });
};

export default connect(
    mapStateToProps
)(SideMenu);
