import { Formik } from "formik";
import * as React from "react";
import { FaSearch } from "react-icons/fa";
import { UncontrolledTooltip } from "reactstrap";
import { UsersSearchRequest } from "../../store/UserManagement/UsersSearch/state";

interface Props {
    search(request?: UsersSearchRequest): void;
    isSearching: boolean;
}

export default (props: Props) => {
    const initialValues = {
        query: "",
        role: ""
    };

    const submit = (values: typeof initialValues) => {
        const searchModel: UsersSearchRequest = {
            query: values.query,
            filters: []
        };

        if (!!values.role) {
            searchModel.filters!.push({ field: "role", value: values.role });
        }

        props.search(searchModel);
    };

    return (
        <Formik
            onSubmit={submit}
            validateOnChange
            initialValues={initialValues}>
            {({ values, dirty, handleChange, handleSubmit, handleReset }) =>
                <form onSubmit={handleSubmit}>
                    <div className="input-group mb-3" style={{ maxWidth: "600px" }}>
                        {/* Role filter */}
                        <div className="input-group-prepend">
                            <label className="input-group-text">Role</label>
                        </div>
                        <select
                            style={{ maxWidth: 150 }}
                            value={values.role}
                            name="role"
                            onChange={handleChange}
                            className="form-control">
                            <option value="">Any</option>
                            <option value="user">User</option>
                            <option value="supporter">Supporter</option>
                            <option value="oemadministrator">OEM Administrator</option>
                            <option value="administrator">Administrator</option>
                        </select>

                        {/* Search field */}
                        <input
                            type="text"
                            name="query"
                            value={values.query}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Search users" />

                        <div className="input-group-append">
                            {/* Search button */}
                            <button
                                id="search-btn"
                                type="submit"
                                disabled={props.isSearching}
                                className="btn btn-primary">
                                <FaSearch className="mt-n1" />
                            </button>
                            <UncontrolledTooltip placement="top" target="search-btn">Search</UncontrolledTooltip>
                        </div>
                    </div>
                </form>
            }
        </Formik>
    );
};
