import { UserManager, UserManagerSettings } from "oidc-client";
import { createUserManager } from "redux-oidc";
import { ApiStatus } from "./ApiGateway";
import { KnownRoles } from "./store";
import * as Yup from "yup";
import { isOemUwg5 } from "./components/Common";

let userManager: UserManager;

/**
 * Check if the specified violation exists in the api status details.
 */
export const hasViolation = (status: ApiStatus, violationDescription: string) => {
    try {
        return status.fieldViolations
            .map(violation => violation.message.toUpperCase())
            .some(normalizedMessage => normalizedMessage.includes(violationDescription.toUpperCase()));
    } catch (e) {
        return false;
    }
};

/**
 * Initialize user manager with UserManagerSettings.
 * Throws error if the user manager has already been initialized.
 */
export const initializeUserManager = (settings: UserManagerSettings) => {
    if (userManager === undefined) {
        userManager = createUserManager(settings);
        return userManager;
    } else {
        throw Error("User manager is already initialized");
    }
};

/**
 * Get user manager.
 * Throws error if the user manager has not been initialized.
 */
export const getUserManager = () => {
    if (userManager === undefined) {
        throw Error("User manager is not initialized");
    } else {
        return userManager;
    }
};

/**
 * Redirect to user to the sign in page. The user will be redirected
 * back to the current pathname after signing in.
 */
export const signIn = () => {
    const redirectUrl = window.location.pathname || "/";
    const args = { data: { uri: redirectUrl } };
    getUserManager().signinRedirect(args);
};

/**
 * Sign out user.
 */
export const signOut = () => {
    getUserManager().signoutRedirect();
};

/**
 * Convert phrase into title case.
 * "hello world" => "Hello World".
 */
export const toTitleCase = (phrase: string) => {
    return phrase
        .toLowerCase()
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

/**
 * Pluralize a word if count != 1. Default suffix is "s".
 * Example (count: 2, noun: "user") returns "2 users".
 */
export const maybePluralize = (count: number, noun: string, suffix: string = "s") => {
    let sanitizedCount = count;
    if (count === undefined || count === null) {
        sanitizedCount = 0;
    }
    return `${sanitizedCount} ${noun}${sanitizedCount !== 1 ? suffix : ""}`;
};

/**
 * Compute classNames for an input element.
 */
export const getInputClassNames = (options?: { isValid?: boolean, small?: boolean }) => {
    let classNames = "form-control";

    if (options && options.small) {
        classNames += "-sm";
    }

    if (options && options.isValid !== undefined && !options.isValid) {
        classNames += " is-invalid";
    }

    return classNames;
};

/**
 * Format: January 01, 1970, 00:00 AM
 */
export const formatDateTime = (datetime: Date | string) => {
    // make sure it's really a date, not just ISO timestamp string
    const date = new Date(datetime);

    if (isNaN(date.getTime())) {
        throw new Error(`'${datetime}' is an invalid date!`);
    }

    const customFormat = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    });
    return customFormat.format(date);
};

/**
 * Format: DD/MM HH:mm
 */
export const formatShortDateTime = (datetime: Date | string) => {
    // make sure it's really a date, not just ISO timestamp string
    const date = new Date(datetime);

    if (isNaN(date.getTime())) {
        throw new Error(`'${datetime}' is an invalid date!`);
    }

    const customFormat = new Intl.DateTimeFormat("en-GB", {
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    });
    return customFormat.format(date);
};

/**
 * Format time in 12h or 24h, default is 24h.
 * 12h example: 01.37 PM
 * 24h exmaple: 13.37
 */
export const formatTime = (date: Date | string, format: "12h" | "24h" = "24h") => {
    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
        throw new Error(`'${date}' is an invalid date!`);
    }

    const locale = format === "24h" ? "da-DK" : "en-US";

    const customFormat = new Intl.DateTimeFormat(locale, {
        hour: "2-digit",
        minute: "2-digit"
    });

    return customFormat.format(dateObj);
};

/**
 * Add minutes to a Date.
 */
export const addMinutes = (date: Date | string, minutes: number) => {
    const oldDate = new Date(date);
    return new Date(oldDate.getTime() + (minutes * 60000));
};

/**
 * Creates Yup schema.
 */
export const validationSchemaCreators = {
    firstNameSchema: () => (isOemUwg5 ? Yup.string()
        .min(2, "*First name must have at least 2 characters")
        .max(100, "*First names can't be longer than 100 characters")
        .matches(/^[À-ÿ\w\s!#()=+\-?_*'@,.;:¨~^]+$/, "Must have no invalid characters")
        .notRequired()
        : Yup.string()
            .min(2, "*First name must have at least 2 characters")
            .max(100, "*First names can't be longer than 100 characters")
            .matches(/^[À-ÿ\w\s!#()=+\-?_*'@,.:¨~^]+$/, "Must have no invalid characters")
            .notRequired()),
    lastNameSchema: () => (isOemUwg5 ? Yup.string()
        .min(2, "*Last name must have at least 2 characters")
        .max(100, "*Last names can't be longer than 100 characters")
        .matches(/^[À-ÿ\w\s!#()=+\-?_*'@,.;:¨~^]+$/, "Must have no invalid characters")
        .notRequired()
        : Yup.string()
            .min(2, "*Last name must have at least 2 characters")
            .max(100, "*Last names can't be longer than 100 characters")
            .matches(/^[À-ÿ\w\s!#()=+\-?_*'@,.:¨~^]+$/, "Must have no invalid characters")
            .notRequired()),
    addressSchema: () => Yup.string()
        .min(5, "*Address must have at least 5 characters")
        .max(120, "*Addresses can't be longer than 120 characters")
        .matches(/^[À-ÿ\w\s!#()=+\-?_*'@,.;:¨~^]+$/, "Must have no invalid characters")
        .notRequired(),
    citySchema: () => Yup.string()
        .min(2, "*City must have at least 2 characters")
        .max(50, "*Cities can't be longer than 50 characters")
        .matches(/^[À-ÿ\w\s!#()=+\-?_*'@,.;:¨~^]+$/, "Must have no invalid characters")
        .notRequired(),
    postalCodeSchema: () => Yup.string()
        .min(3, "*Postal code must have at least 3 characters")
        .max(10, "*Postal codes can't be longer than 10 characters")
        .matches(/^[À-ÿ\w\s!#()=+\-?_*'@,.;:¨~^]+$/, "Must have no invalid characters")
        .notRequired(),
    countryIsoCodeSchema: () => Yup.string()
        .matches(/^[a-zA-Z]*$/, "Must be only latin letters")
        .notRequired(),
    phoneNumberSchema: () => Yup.string()
        .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, "*Phone number is not valid")
        .notRequired(),
    emailSchema: () => (isOemUwg5 ? Yup.string()
        .email("*Username is not valid")
        .required("*Username is required")
        : Yup.string()
            .email("*Email is not valid")
            .required("*Email is required")),
    emailRoleBasedSchema: () => Yup.string()
        .email("*Email is not valid")
        .when("role", {
            is: (roleSchema: string) =>
                roleSchema?.toUpperCase() === KnownRoles.Administrator.toUpperCase() ||
                roleSchema?.toUpperCase() === KnownRoles.OemAdministrator.toUpperCase() ||
                roleSchema?.toUpperCase() === KnownRoles.Supporter.toUpperCase(),
            then: Yup.string().required("*Email is required"),
            otherwise: Yup.string().notRequired()
        }),
    passwordSchema: () => Yup.string()
        .required("Password is required")
        .test("password", "The password contains an invalid character", PasswordUtility.containsInvalidCharacter)
        .matches(/^(?!.*(.)\1{2,}).+$/, "The password contains too many repeated characters")
        .test(
            "password",
            "The password must meet at least 3 of the following complexity rules:\n" +
            "At least 1 lowercase character (a-z)\n" +
            "At least 1 uppercase character (A-Z)\n" +
            "At least 1 digit (0-9)\n" +
            "At least 1 special character: !\"#$%&'()*+,-./:;<=>?@[\\]^_` {|}~",
            PasswordUtility.meetsEnoughComplexityRules)
        .min(10, "The password must be minimum 10 characters in length")
        .max(128, "The password must be maximum 128 characters in length"),
    roleSchema: () => Yup.string()
        .required("*Role is required"),
    usernameSchema: () => Yup.string()
        .min(4, "The username must be minimum 4 characters in length")
        .max(128, "The username must be maximum 128 characters in length")
        .matches(/^(?=.{4,128}$)[a-z0-9]+([_.-]?[a-z0-9])*$/i, "The username is invalid")
        .required("*Username is required"),
    privateLabelIdSchema: () => Yup.string()
        .matches(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i, "*Private label must be selected")
        .required("*Private label must be selected"),
    visibilitySchema: () => Yup.mixed<string>()
        .required(),
    axVersionSchema: () => Yup.string()
        .max(20, "*Ax version can't be longer than 20 characters")
        .matches(/^[a-zA-Z0-9]*$/, "Must be only numbers or latin letters")
        .required("*Ax version is required"),
    swVersionSchema: () => Yup.string()
        .min(4, "*Software version must have at least 4 characters")
        .max(11, "*Software version can't be longer than 11 characters")
        .matches(/^[a-zA-Z0-9]*$/, "Must be only numbers or latin letters")
        .required("*Software version is required"),
    wifiVersionSchema: () => Yup.string()
        .max(20, "*Wifi version can't be longer than 20 characters")
        .matches(/^[a-zA-Z0-9._-]*$/, "Must be only numbers, latin letters or .-_")
        .required("*Wifi version is required"),
    hwVersionsSchema: () => Yup.array<string>()
        .max(10, "*Hardware version can't have more than 10 versions")
        .required("*Hardware version is required"),
    productVersionSchema: () => Yup.string()
        .required("*Product is required"),
    changelogSchema: () => Yup.string()
        .max(750, "*Release notes can't be longer than 750 characters")
        .matches(/^[À-ÿ\w\s!#()=+\-?_*'@,.;:¨~^]+$/, "Must have no invalid characters")
        .required("*Release notes is required"),
    notesSchema: () => Yup.string()
        .max(150, "*Internal notes can't be longer than 150 characters")
        .matches(/^[À-ÿ\w\s!#()=+\-?_*'@,.;:¨~^]+$/, "Must have no invalid characters")
        .notRequired(),
    privateLabelNameSchema: () => Yup.string()
        .min(2, "*Private label name must have at least 2 characters")
        .max(20, "*Private label name can't be longer than 20 characters")
        .matches(/^[a-z0-9-]+$/i, "*Private label name can contain letters, numbers as hyphens only")
        .required("*Private label name is required"),
    privateLabelDisplayNameSchema: () => Yup.string()
        .min(1, "*Private label display name must have at least 1 character")
        .max(50, "*Private label display name can't be longer than 50 characters")
        .matches(
            /^[À-ÿ\w\s!#()=+\-?_*'@,.;:¨~^]+$/,
            "*Private label display name can contain:\n" +
            "- Upper and lowercase letters (aA-zZ), numbers (0-9).\n" +
            "- Letters (aA-zZ) combined with the following characters: ´ ` ^ ~ ¨ (e.g. àáâãä ÀÁÂÃÄ)\n" +
            "- Additional special characters: !#()=+-?_*'@,.;:¨~^\n" +
            "- All other characters are not allowed")
        .required("*Private label display name is required"),
    privateLabelAppIdentifierSchema: () => Yup.string()
        .min(1, "*Private label app identifier must have at least 1 character")
        .max(50, "*Private label app identifier can't be longer than 50 characters")
        .matches(
            /^[À-ÿ\w\s!#()=+\-?_*'@,.;:¨~^]+$/,
            "*Private label app identifier can contain:\n" +
            "- Upper and lowercase letters (aA-zZ), numbers (0-9).\n" +
            "- Letters (aA-zZ) combined with the following characters: ´ ` ^ ~ ¨ (e.g. àáâãä ÀÁÂÃÄ)\n" +
            "- Additional special characters: !#()=+-?_*'@,.;:¨~^\n" +
            "- All other characters are not allowed")
        .notRequired(),
    privateLabelNotificationTitleSchema: () => Yup.string()
        .min(4, "*Private label notification title must have at least 4 characters")
        .max(60, "*Private label notification title can't be longer than 60 characters")
        .matches(
            /^[À-ÿ\w\s!#()=+\-?_*'@,.;:¨~^]+$/,
            "*Private label notification title can contain:\n" +
            "- Upper and lowercase letters (aA-zZ), numbers (0-9).\n" +
            "- Letters (aA-zZ) combined with the following characters: ´ ` ^ ~ ¨ (e.g. àáâãä ÀÁÂÃÄ)\n" +
            "- Additional special characters: !#()=+-?_*'@,.;:¨~^\n" +
            "- All other characters are not allowed")
        .required(),
    distributorIdSchema: () => Yup.number()
        .min(1, "*Distributor ID must be positive number")
        .max(4294967295, "*Distributor ID can't be greater than 4294967295")
        .round("trunc")
        .required("*Distributor ID is required"),
    distributorCustomerCodeSchema: () => Yup.string()
        .min(1, "*Customer name must have at least 1 character")
        .max(20, "*Customer code can't be longer than 20 characters")
        .matches(/^[a-z0-9-]+$/i, "Customer code can contain letters, numbers as hyphens only")
        .notRequired(),
    distributorCustomerNameSchema: () => Yup.string()
        .min(1, "*Customer name must have at least 1 character")
        .max(80, "*Customer name can't be longer than 80 characters")
        .matches(/^[a-z0-9-\s]+$/i, "*Customer name can contain letters, numbers, hyphens and spaces only")
        .required("*Customer name is required"),
    distributorDescriptionSchema: () => Yup.string()
        .max(250, "*Distributor description can't be longer than 250 characters")
        .matches(
            /^[À-ÿ\w\s!#()=+\-?_*'@,.;:¨~^]+$/,
            "*Distributor description can contain:\n" +
            "- Upper and lowercase letters (aA-zZ), numbers (0-9).\n" +
            "- Letters (aA-zZ) combined with the following characters: ´ ` ^ ~ ¨ (e.g. àáâãä ÀÁÂÃÄ)\n" +
            "- Additional special characters: !#()=+-?_*'@,.;:¨~^\n" +
            "- All other characters are not allowed")
        .notRequired(),
    emptyEmailSchema: () => Yup.string()
        .notRequired(),
    privateLabelAppUriSchema: () => Yup.string()
        .url()
        .required()
};

/**
 * Check if the value is a valid GUID. NIL-GUID (00000000-0000-0000-0000-000000000000) is NOT considered a valid GUID.
 */
export const isValidGuid = (value: string | undefined | null) => {
    if (!value) {
        return false;
    }

    const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return re.test(value) && value !== "00000000-0000-0000-0000-000000000000";
};

/**
 * Formats a byte size into readable format
 */
export const formatByteSize = (size: number, decimals: number = 2): string => {
    if (size === 0) {
        return "0 B";
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(size) / Math.log(k));

    return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const PasswordUtility = (() => {
    // Configuration
    const minimumComplexityRulesMet = 3;
    const upperCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerCharacters = "abcdefghijklmnopqrstuvwxyz";
    const digits = "0123456789";
    const specialCharacters = "!\"#$%&'()*+,-./:;<=>?@[\\]^_` {|}~";
    const allValidCharacters = upperCharacters + lowerCharacters + digits + specialCharacters;

    /**
     * Checks if the password contains an invalid character.
     */
    const containsInvalidCharacter = (password: string | null | undefined): boolean => {
        for (const c of password || "") {
            if (!allValidCharacters.includes(c)) {
                return false;
            }
        }
        return true;
    };

    /**
     * Checks if the password meets enough complexity rules.
     */
    const meetsEnoughComplexityRules = (password: string | null | undefined): boolean => {
        if (!password) {
            return false;
        }

        const anyCharInArray = (value: string, array: string[]): boolean => {
            for (const c of array) {
                if (value.includes(c)) {
                    return true;
                }
            }
            return false;
        };

        const containsUpper = anyCharInArray(password, Array.from(upperCharacters));
        const containsLower = anyCharInArray(password, Array.from(lowerCharacters));
        const containsDigit = anyCharInArray(password, Array.from(digits));
        const containsSpecial = anyCharInArray(password, Array.from(specialCharacters));
        const complexityRulesMet =
            Number(containsUpper) +
            Number(containsLower) +
            Number(containsDigit) +
            Number(containsSpecial);

        return complexityRulesMet >= minimumComplexityRulesMet;
    };

    return {
        containsInvalidCharacter,
        meetsEnoughComplexityRules
    };
})();

/**
 * Format a date into a UTC format
 */
export const formatUTCDate = (date: Date | String): string => {
    // Convert date parameter to Date object
    let dateObj;
    if (typeof date === "string") {
        dateObj = new Date(date);
    } else {
        dateObj = new Date(date as Date);
    }
    // Create formatted date string from date object
    let dateStr = (dateObj as Date).toUTCString();
    dateStr = dateStr.substring(0, dateStr.length - 4) + " UTC ";
    dateStr = dateStr + getTimeDeltaLabel((dateObj as Date));

    return dateStr;
};

interface Duration {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export const secondsToDuration = (seconds?: number): string | null => {
    if (seconds === undefined || seconds === null) {
        return null;
    }

    const days = Math.floor(seconds / 86400); // 1 day = 24 * 60 * 60 seconds
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return formatDuration({
        days,
        hours,
        minutes,
        seconds: remainingSeconds,
    });
};

export const formatDuration = (duration: Duration): string => {
    const { days, hours, minutes, seconds } = duration;

    const formattedDays = days > 0 ? `${days}day(s) ` : "";
    const formattedHours = hours > 0 ? `${hours}hour(s) ` : "";
    const formattedMinutes = minutes > 0 ? `${minutes}min(s) ` : "";
    const formattedSeconds = seconds > 0 ? `${seconds}sec(s)` : "";

    return `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`;
};

/**
 * Creates and returns an approximate time since a given date
 */
export const getTimeDeltaLabel = (target: Date): string => {
    const now = new Date();

    const delta = now.getTime() - target.getTime();

    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    if (delta >= 0) {
        if (delta / 1000 < 60) {
            return "(Just now)";
        } else if (delta < msPerMinute) {
            return "(" + Math.round(delta / 1000) + " second(s) ago)";
        } else if (delta < msPerHour) {
            return "(" + Math.round(delta / msPerMinute) + " minute(s) ago)";
        } else if (delta < msPerDay) {
            return "(" + Math.round(delta / msPerHour) + " hour(s) ago)";
        } else if (delta < msPerMonth) {
            return "(" + Math.round(delta / msPerDay) + " day(s) ago)";
        } else if (delta < msPerYear) {
            return "(" + Math.round(delta / msPerMonth) + " month(s) ago)";
        } else {
            return "(" + Math.round(delta / msPerYear) + " year(s) ago)";
        }
    } else if (delta < 0) {
        if (delta / 1000 > -60) {
            return "(Just now)";
        } else if (delta > -msPerMinute) {
            return "(" + Math.abs(Math.round(delta / 1000)) + " second(s) from now)";
        } else if (delta > -msPerHour) {
            return "(" + Math.abs(Math.round(delta / msPerMinute)) + " minute(s) from now)";
        } else if (delta > -msPerDay) {
            return "(" + Math.abs(Math.round(delta / msPerHour)) + " hour(s) from now)";
        } else if (delta > -msPerMonth) {
            return "(" + Math.abs(Math.round(delta / msPerDay)) + " day(s) from now)";
        } else if (delta > -msPerYear) {
            return "(" + Math.abs(Math.round(delta / msPerMonth)) + " month(s) from now)";
        } else {
            return "(" + Math.abs(Math.round(delta / msPerYear)) + " year(s) from now)";
        }
    } else {
        return "(Invalid date)";
    }
};

/**
 * Used as a quick way to check String data for undefined or null fields.
 * @returns String of object or "No data".
 */
export const undefNullCheck = (data: any): string => {
    return data === undefined || data === null || data === "" ? "No data" : String(data);
};

/**
 * Converts a JSON data string into a Date type object by passing the value,
 * by reference, using an object. Also returns the newly created Date object.
 */
export function toDate(dateValue: string | Date | undefined): Date | null {
    if (typeof dateValue === "string") {
        const date = new Date(dateValue);
        if (isNaN(date.getTime())) {
            return null;
        }
        return date;
    } else if (dateValue instanceof Date) {
        return dateValue;
    }
    return null;
}