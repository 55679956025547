import { RequestState } from "../../sharedTypes";

export interface CreateUserState {
    createUserRequestState: RequestState;
    newUserId?: string;
    rejectReason?: RejectReason;
}

export enum RejectReason {
    EmailAlreadyUsed,
    UsernameAlreadyUsed,
    InternalError
}

export interface NewUserProfile {
    firstName: string | undefined;
    lastName: string | undefined;
    address: string | undefined;
    city: string | undefined;
    postalCode: string | undefined;
    countryIsoCode: string | undefined;
    phoneNumber: string | undefined;
    role: string;
    email: string | undefined;
    username: string | undefined;
    password: string;
    privateLabelId: string;
}
