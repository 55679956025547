/* eslint-disable complexity */
import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { UsersSearchResponse, UsersSearchState } from "./state";

const unloadedState: UsersSearchState = {
    searchRequestState: RequestState.NotStarted,
    deleteUserRequestState: RequestState.NotStarted,
    searchResult: undefined,
    gdprdata: undefined,
    getRequestState: RequestState.NotStarted
};

export const reducer: Reducer<UsersSearchState> = (state: UsersSearchState | undefined, incomingAction: Action): UsersSearchState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        // GDPR
        case "REQUEST_GDPRDATA_BY_USERID":
            return {
                ...state, getRequestState: RequestState.InProgress,
                gdprdata: undefined
            };

        case "RECEIVE_GDPRDATA_BY_USERID":
            return {
                ...state,
                getRequestState: RequestState.Succeeded,
                gdprdata: action.gdprData
            };
        case "REJECT_GDPRDATA_BY_USERID":
            return {
                ...state,
                getRequestState: RequestState.Failed,
                gdprdata: undefined
            };
        case "RESET_GDPRDATA_STATE":
            return {
                ...state,
                getRequestState: RequestState.NotStarted,
            };

        // USERSDATA
        case "REQUEST_USERSDATA":
            return {
                ...state, getRequestState: RequestState.InProgress,
                gdprdata: undefined
            };

        case "RECEIVE_USERSDATA":
            return {
                ...state,
                getRequestState: RequestState.Succeeded,
                gdprdata: action.gdprData
            };
        case "REJECT_USERSDATA":
            return {
                ...state,
                getRequestState: RequestState.Failed,
                gdprdata: undefined
            };
        case "RESET_USERSDATA_STATE":
            return {
                ...state,
                getRequestState: RequestState.NotStarted,
            };

        // Search
        case "REQUEST_SEARCH_USERS":
            return { ...state, searchRequestState: RequestState.InProgress, deleteUserRequestState: RequestState.NotStarted };
        case "RECEIVE_SEARCH_USERS":
            return { ...state, searchRequestState: RequestState.Succeeded, searchResult: action.searchResult };
        case "REJECT_SEARCH_USERS":
            return { ...state, searchRequestState: RequestState.Failed };

        // Delete user
        case "REQUEST_DELETE_USER":
            return { ...state, deleteUserRequestState: RequestState.InProgress };
        case "REJECT_DELETE_USER":
            return { ...state, deleteUserRequestState: RequestState.Failed };
        case "RECEIVE_DELETE_USER":
            const newSearchResult: UsersSearchResponse = {
                items: state.searchResult!.items.filter(u => u.id !== action.userId),
                total: state.searchResult!.total - 1
            };

            return {
                ...state,
                searchResult: newSearchResult,
                deleteUserRequestState: RequestState.Succeeded
            };

        default:
            return state;
    }
};
