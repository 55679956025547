import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState, RequestState } from "../../store";
import { IAuthProps } from "../../store/authTypes";
import { actionCreators } from "../../store/ThermostatDetails/actionCreators";
import { ThermostatDetailsState } from "../../store/ThermostatDetails/state";
import { MessageBox, MessageBoxType, Page, Spinner, withAuthProps, withCommonProps } from "../Common";
import { CommonState } from "../../store/Common/state";
import { IconContext } from "react-icons";
import { FaPrint, FaRedoAlt } from "react-icons/fa";
import "./ThermostatDetailsPage.css";
import { InformationTab } from "./InformationTab";
import { StateTab } from "./StateTab";
import { WifiSettingsTab } from "./WifiSettingsTab";
import { OtherSettingsTab } from "./OtherSettingsTab";
import { GUIDTab } from "./GUIDTab";
import { TabButton } from "../Common/TabButton";

export type ThermostatDetailsProps =
    ThermostatDetailsState &
    typeof actionCreators &
    IAuthProps &
    CommonState &
    RouteComponentProps<{ id: string }>;

enum Tab {
    Info,
    State,
    Wifi,
    Other,
    GUIDs
}

export const distributorLabelFormat = (props: ThermostatDetailsProps): string => {
    const customerName = props.distributors?.find(x => x.id === props.thermostat?.internalDistributorId)?.name || "N/A";
    const privateLabelName = props.privateLabels?.find(x => x.id === props.thermostat?.privateLabelId)?.name || "N/A";
    return `${customerName} (DID: ${props.thermostat?.distributorId}, PL: ${privateLabelName})`;
};

export const formatTwoDecimals = (num: number | undefined): string | number => {
    return num !== undefined ? num === 0 ? 0 : num.toPrecision(4) : "No data";
};

// eslint-disable-next-line complexity
const ThermostatDetailsPage = (props: ThermostatDetailsProps) => {
    const shouldFetch = props.isAuthenticated && props.requestedThermostatId !== props.match.params.id;

    React.useEffect(() => {
        if (shouldFetch) {
            fetchData();
        }
    }, [shouldFetch]);

    // Indicates which tab to show
    const [tab, setTab] = React.useState(Tab.Info);
    const [showAllTabs, setShowAllTabs] = React.useState(false);
    const [generatedDate, setGeneratedDate] = React.useState(new Date());

    // Render if loading thermostat
    if (props.loadThermostatRequestState === RequestState.InProgress) {
        return <Spinner description="Loading..." />;
    }

    // Render if loading thermostat failed
    if (props.loadThermostatRequestState === RequestState.Failed || !props.thermostat) {
        return <MessageBox
            title="Failed to load thermostat"
            description="An error occurred when loading thermostat."
            type={MessageBoxType.Error}>
            <button
                className="btn btn-primary mt-3"
                onClick={() => props.loadThermostat(props.match.params.id)}>Try again</button>
        </MessageBox>;
    }

    function fetchData() {
        props.loadThermostat(props.match.params.id);
        setGeneratedDate(new Date());
    }

    function getFormatDateGenerated(): string {
        const dateStr = generatedDate.toUTCString();
        return dateStr.substring(0, dateStr.length - 4) + " UTC";
    }

    // Render
    return (
        <IconContext.Provider value={{ className: "header-react-icons" }}>
            <Page id="thermostats-page" title="Thermostat Information and Settings">
                <div className="pb-2 d-flex justify-content-between">
                    <div className="row d-flex align-items-center ml-0">
                        <button className="btn btn-primary mr-1" onClick={() => fetchData()}><FaRedoAlt size={18} className="mt-n1" /></button>
                        <TabButton text="All" active={showAllTabs} onClick={() => {
                            setShowAllTabs(true);
                        }} />
                        <TabButton text="Information" active={tab === Tab.Info && !showAllTabs} onClick={() => {
                            setTab(Tab.Info);
                            setShowAllTabs(false);
                        }} />
                        <TabButton text="State" active={tab === Tab.State && !showAllTabs} onClick={() => {
                            setTab(Tab.State);
                            setShowAllTabs(false);
                        }} />
                        <TabButton text="WiFi settings" active={tab === Tab.Wifi && !showAllTabs} onClick={() => {
                            setTab(Tab.Wifi);
                            setShowAllTabs(false);
                        }} />
                        <TabButton text="Other settings" active={tab === Tab.Other && !showAllTabs} onClick={() => {
                            setTab(Tab.Other);
                            setShowAllTabs(false);
                        }} />
                        {(props.isAdmin || props.isOemAdmin) &&
                            <TabButton text="GUIDs" active={tab === Tab.GUIDs && !showAllTabs} onClick={() => {
                                setTab(Tab.GUIDs);
                                setShowAllTabs(false);
                            }} />
                        }
                    </div>
                    <div className="">
                        <button className="btn btn-primary" onClick={() => window.print()} style={{ float: "right" }}><FaPrint size={18} className="mt-n1" /></button>
                    </div>
                </div>
                <p className="mb-1 ml-1" style={{ fontSize: "1rem" }}>Generated {getFormatDateGenerated()}</p>
                <div className="card-columns">
                    {(showAllTabs || tab === Tab.Info) && <InformationTab {...props} />}
                    {(showAllTabs || tab === Tab.State) && <StateTab {...props} />}
                    {(showAllTabs || tab === Tab.Wifi) && <WifiSettingsTab {...props} />}
                    {(showAllTabs || tab === Tab.Other) && <OtherSettingsTab {...props} />}
                    {(props.isAdmin || props.isOemAdmin) && (showAllTabs || tab === Tab.GUIDs) && <GUIDTab {...props} />}
                </div>
            </Page>
        </IconContext.Provider>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => state.thermostatDetails,
    actionCreators
)(ThermostatDetailsPage as any)));
