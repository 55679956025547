/* eslint-disable complexity */
import * as React from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { ApplicationState } from "../../store";
import { IAuthProps } from "../../store/authTypes";
import {
    ConfirmAlert,
    MessageBox,
    MessageBoxType,
    Page,
    PermissionDeniedPage,
    Spinner,
    withAuthProps,
    withCommonProps
} from "../Common";
import { RequestState } from "../../store/sharedTypes";
import { UncontrolledTooltip } from "reactstrap";
import { formatDateTime, maybePluralize } from "../../utils";
import { DistributorListItem, DistributorSearchState } from "../../store/Distributors/DistributorsList/state";
import { actionCreators } from "../../store/Distributors/DistributorsList/actionCreators";
import { DefaultSettings } from "../../store/Distributors/DistributorsList/reducer";
import DistributorsSearchForm from "../Distributors/DistributorsSearchForm";
import { CommonState } from "../../store/Common/state";
import { getDataTableTheme } from "../../DataTableThemes";

type DistributorsListPageProps =
    DistributorSearchState &
    CommonState &
    typeof actionCreators &
    IAuthProps &
    RouteComponentProps;

const DistributorsListPage = (props: DistributorsListPageProps) => {
    // Ensure data is fetched
    const shouldFetchData = props.searchRequestState !== RequestState.Failed
        && props.searchRequestState !== RequestState.InProgress
        && props.isAuthenticated;

    if (shouldFetchData) {
        if (!props.searchResult) {
            props.searchDistributors();
        }
    }

    // Component state
    const [selectedDistributors, setSelectedDistributors] = React.useState([] as DistributorListItem[]);
    const [clearRows, setClearRows] = React.useState(false);
    const pageTitle = "Distributors";

    if (!props.isAdmin && !props.isSupporter && !props.isOemAdmin) {
        return <PermissionDeniedPage pageTitle={pageTitle} history={props.history} />;
    }

    const createDistributorButton =
        <NavLink
            className="btn btn-primary btn-sm mb-3"
            to="/distributors/new/">
            <FaPlus className="mt-n1" /> Create new
        </NavLink>;

    const contextComponent = (
        <>
            {maybePluralize(selectedDistributors.length, "distributor", "s")} selected
            <button
                className="btn btn-danger btn-sm ml-2"
                disabled={selectedDistributors.length === 0}
                onClick={e => {
                    e.preventDefault();
                    confirmAlert({
                        customUI: ({ onClose }) => <ConfirmAlert
                            title="Are you sure?"
                            description={`You are about to permanently delete ${maybePluralize(selectedDistributors.length, "distributor", "s")}!`}
                            onCancel={onClose}
                            onConfirm={() => {
                                props.deleteDistributors(selectedDistributors.map(u => u.id));
                                onClose();
                                setClearRows(true);
                            }}
                            confirmText="Yes, I am sure"
                            cancelText="Cancel" />
                    });
                }}><FaTrash className="mt-n1" /> Delete</button>
        </>);

    const columns: IDataTableColumn[] = [
        { name: "Distributor ID", selector: "distributorId", sortable: true, maxWidth: "125px" },
        { name: "Customer Name", selector: "customerName", sortable: true, maxWidth: "250px" },
        { name: "Customer Code", selector: "customerCode", sortable: true, maxWidth: "250px" },
        { name: "Created At", selector: "createdDate", cell: s => formatDateTime(s.createdDate), sortable: true, maxWidth: "250px" },
        { name: "Description", selector: "description", sortable: true, maxWidth: "350px" }
    ];

    if (props.isAdmin || props.isOemAdmin) {
        // only admin can see private label column (makes no sense for supporter)
        columns.unshift({ name: "Private Label", selector: "privateLabelName", sortable: true, maxWidth: "150px" });

        // only admin can access actions
        columns.push({
            name: "Actions",
            cell: (distributor: DistributorListItem) => (
                <div className="w-100" hidden={props.isOemAdmin}>
                    <NavLink
                        id={`edit-${distributor.id}`}
                        className={"btn shadow-none text-dark btn-sm mt-n1"}
                        to={`/distributors/edit/${distributor.id}`}                            >
                        <FaEdit size={15} />
                    </NavLink>
                    <UncontrolledTooltip
                        placement="top"
                        target={`edit-${distributor.id}`}>Edit {distributor.customerName} ({distributor.distributorId})
                    </UncontrolledTooltip>

                    <button
                        id={`delete-${distributor.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        type="button"
                        disabled={props.deleteRequestState === RequestState.InProgress}
                        onClick={e => {
                            e.preventDefault();
                            confirmAlert({
                                customUI: ({ onClose }) => <ConfirmAlert
                                    title="Are you sure?"
                                    description={`Are you sure you want to permanently delete distributor ${distributor.customerName} (${distributor.distributorId})?`}
                                    onCancel={onClose}
                                    onConfirm={() => {
                                        props.deleteDistributor(distributor.id);
                                        onClose();
                                    }}
                                    confirmText="Yes, I am sure"
                                    cancelText="Cancel" />
                            });
                        }}>
                        <FaTrash size={15} />
                    </button>
                    <UncontrolledTooltip
                        placement="top"
                        target={`delete-${distributor.id}`}>Delete {distributor.customerCode} ({distributor.distributorId})
                    </UncontrolledTooltip>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            maxWidth: "50px"
        });
    }

    return (
        <Page title={pageTitle} id="search-distributors-page">
            { props.isAdmin && createDistributorButton }
            <DistributorsSearchForm
                privateLabels={props.isAdmin || props.isOemAdmin ? props.privateLabels : []}
                searchFormValues={props.searchFormValues}
                changeRequest={(request) => props.changeDistributorSearchRequest({ ...request, offset: 0 })}
                search={props.searchDistributors}
                isSearching={props.searchRequestState === RequestState.InProgress} />
            {props.deleteRequestState === RequestState.Failed &&
                <MessageBox
                    type={MessageBoxType.Error} className="mb-3" title="Failed to delete"
                    description={props.deleteError ?? "An error occurred when deleting distributor."} />}
            {props.searchRequestState === RequestState.Failed ? (
                <MessageBox
                    type={MessageBoxType.Error}
                    title="Failed to load data"
                    description="An error occurred when loading distributors.">
                    <button
                        className="btn btn-primary mt-3"
                        onClick={() => props.searchDistributors()}>Try again</button>
                    <button
                        className="btn btn-primary mt-3 ml-3"
                        onClick={() => {
                            props.changeDistributorSearchRequest({
                                limit: DefaultSettings.defaultPageSize,
                                offset: DefaultSettings.defaultPageNumber * DefaultSettings.defaultPageSize,
                            });
                            props.searchDistributors();
                        }}>Refresh</button>
                </MessageBox>
            ) :
                (<DataTable
                    // Options
                    keyField={"id"}
                    noHeader={!props.searchResult}
                    title={!props.searchResult ? null : `Found ${maybePluralize(props.searchResult.total, "distributor", "s")}`}
                    columns={columns}
                    data={!props.searchResult ? [] : props.searchResult.items}
                    noDataComponent={<p> </p>}

                    // Pagination
                    pagination
                    paginationServer
                    paginationPerPage={props.searchRequest!.limit}
                    paginationRowsPerPageOptions={DefaultSettings.paginationRowsPerPageOptions}
                    paginationTotalRows={!props.searchResult ? 0 : props.searchResult.total}
                    onChangeRowsPerPage={size => {
                        props.changeDistributorSearchRequest({ limit: size });
                        props.searchDistributors();
                    }}
                    // page index in API is zero based but in react-data-table-component it starts from 1
                    onChangePage={page => {
                        props.changeDistributorSearchRequest({ offset: (page - 1) * (props!.searchRequest!.limit || DefaultSettings.defaultPageSize) });
                        props.searchDistributors();
                    }}

                    // Sorting
                    defaultSortAsc={DefaultSettings.defaultSortAsc}
                    defaultSortField={DefaultSettings.defaultSortField} // without this first render doesn't show default sort column and also sort order is stuck in default
                    onSort={(column, sortDirection) => {
                        props.changeDistributorSearchRequest({
                            orderBy: (column.selector as string),
                            orderDescending: sortDirection === "desc"
                        });
                        props.searchDistributors();
                    }}
                    sortServer

                    // Selectable rows
                    clearSelectedRows={clearRows}
                    onSelectedRowsChange={({ selectedRows }) => {
                        setSelectedDistributors(selectedRows);
                        setClearRows(false);
                    }}
                    selectableRows={props.isAdmin}
                    selectableRowsHighlight
                    // Loading/progress
                    progressPending={props.searchRequestState === RequestState.InProgress || props.deleteRequestState === RequestState.InProgress}
                    progressComponent={<Spinner />}

                    // Actions
                    contextComponent={contextComponent}

                    // Theme
                    theme={getDataTableTheme()}
                />)
            }
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.distributorsList }),
    actionCreators
)(DistributorsListPage as any)));
