/* eslint-disable complexity */
// libs
import { Formik } from "formik";
import { confirmAlert } from "react-confirm-alert";
import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { RouteComponentProps } from "react-router";

// components
import { ConfirmAlert, MessageBox, MessageBoxType, Page, Spinner, withAuthProps, withCommonProps, isOemUwg5 } from "../Common";

// state
import { ApplicationState } from "../../store";
import { CommonState } from "../../store/Common/state";
import { IAuthProps } from "../../store/authTypes";
import { actionCreators } from "../../store/UserManagement/EditUser/actionCreators";
import {
    EditUserState,
    ChangePasswordRequest,
    ChangeRoleRequest,
    ChangeEmailRequest,
    ChangeUsernameRequest
} from "../../store/UserManagement/EditUser/state";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { ChangeRoleForm } from "./ChangeRoleForm";
import { ChangeEmailForm } from "./ChangeEmailForm";
import { ChangeUsernameForm } from "./ChangeUsernameForm";
import { RequestState } from "../../store/sharedTypes";
import { addMinutes, formatTime, validationSchemaCreators } from "../../utils";

type EditUserProps =
    EditUserState &
    CommonState &
    typeof actionCreators &
    IAuthProps &
    RouteComponentProps<{ userId: string }>;

const EditUser = (props: EditUserProps) => {
    const [changePassword, setChangePassword] = React.useState(false);
    const pageTitle = "Edit User";
    const userId = props.match.params.userId;
    const { resetEditRequestStates } = props;

    React.useEffect(() => {
        return () => {
            // Anything in here is fired on component unmount.
            resetEditRequestStates();
        };
    }, [resetEditRequestStates]);

    if (props.getUserRequestState === RequestState.NotStarted || (props.existingUser && userId !== props.existingUser.userId)) {
        props.getUserById(userId);
    }

    if (props.getUserRequestState === RequestState.InProgress || props.getUserRequestState === RequestState.NotStarted) {
        return (
            <Page title={pageTitle}>
                <Spinner />
            </Page>
        );
    }

    if (props.getUserRequestState === RequestState.Failed) {
        return <Page title={pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Failed to load"
                description="An error occurred when loading user.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={() => props.getUserById(props.match.params.userId)}>Try again</button>
            </MessageBox>
        </Page>;
    }

    if (!props.existingUser || !props.userInteractionIsValid(props.existingUser.roles[0])) {
        return <Page title={pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Access Denied!"
                description="You do not have permissions to edit this user.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={props.history.goBack}>
                    Go Back
                </button>
            </MessageBox>
        </Page>;
    }

    const isOwnProfile = (): boolean => {
        if (!props.user || !props.existingUser) {
            return false;
        }
        return props.user.profile.sub === props.existingUser.userId;
    };

    type FormValues = {
        firstName: string | undefined,
        lastName: string | undefined,
        address: string | undefined,
        city: string | undefined,
        countryIsoCode: string | undefined,
        languageIsoCode: string | undefined,
        phoneNumber: string | undefined,
        postalCode: string | undefined
    };

    const emptyValues: FormValues = {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        countryIsoCode: "",
        languageIsoCode: "",
        phoneNumber: "",
        postalCode: ""
    };

    const initialValues: FormValues = {
        ...emptyValues,
        ...{
            firstName: props.existingUser.firstName,
            lastName: props.existingUser.lastName,
            address: props.existingUser.address,
            city: props.existingUser.city,
            countryIsoCode: props.existingUser.countryIsoCode,
            languageIsoCode: props.existingUser.languageIsoCode,
            phoneNumber: props.existingUser.phoneNumber,
            postalCode: props.existingUser.postalCode
        }
    };

    const validationSchema = Yup.object().shape<FormValues>({
        firstName: validationSchemaCreators.firstNameSchema(),
        lastName: validationSchemaCreators.lastNameSchema(),
        address: validationSchemaCreators.addressSchema(),
        city: validationSchemaCreators.citySchema(),
        postalCode: validationSchemaCreators.postalCodeSchema(),
        countryIsoCode: validationSchemaCreators.countryIsoCodeSchema(),
        languageIsoCode: validationSchemaCreators.countryIsoCodeSchema(),
        phoneNumber: validationSchemaCreators.phoneNumberSchema(),
    });

    const getInputClassNames = (isInvalid: boolean) => {
        const classNames = "form-control";
        return isInvalid ? classNames + " is-invalid" : classNames;
    };

    const submit = (values: typeof emptyValues) => {
        props.updateUser(userId, values);
    };

    const usernameRequest: ChangeUsernameRequest = {
        username: props.existingUser!.username
    };

    const emailRequest: ChangeEmailRequest = {
        email: props.existingUser!.email
    };

    const roleRequest: ChangeRoleRequest = {
        role: props.existingUser!.roles[0]
    };

    const passwordRequest: ChangePasswordRequest = {
        newPassword: "",
        oldPassword: ""
    };

    const editPasswordComponent = () => {
        return <>
            {!changePassword ? (
                <button className="btn btn-sm btn-warning mb-3" onClick={() => setChangePassword(true)}>Change Password</button>
            ) :
                (<ChangePasswordForm
                    changePassword={(r) => props.changePassword(props.existingUser!.userId, r)}
                    changePasswordError={props.changePasswordError}
                    cancelEdit={() => setChangePassword(false)}
                    changePasswordRequest={passwordRequest}
                    isSubmitting={props.changePasswordRequestState === RequestState.InProgress}
                    isSuccessful={props.changePasswordRequestState === RequestState.Succeeded} />
                )}
        </>;
    };

    return (
        <Page title={pageTitle} id="edit-user-page">
            <div className="row">
                <div className="col">
                    <Formik
                        enableReinitialize={true}
                        onSubmit={submit}
                        validateOnChange
                        validationSchema={validationSchema}
                        initialValues={initialValues}>
                        {({ values, dirty, errors, touched, isValid, handleChange, handleBlur, resetForm, handleSubmit, handleReset, setFieldTouched }) =>
                            <form onSubmit={e => e.preventDefault()}>
                                {/* First name */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="firstName">First name </label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <input name="firstName" value={values.firstName} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.firstName && errors.firstName))} />
                                    <div className="invalid-feedback">{errors.firstName}</div>
                                </div>

                                {/* Last name */}
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="lastName">Last name</label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <input name="lastName" value={values.lastName} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.lastName && errors.lastName))} />
                                    <div className="invalid-feedback">{errors.lastName}</div>
                                </div>

                                {/* Phone number */}
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="phoneNumber">Phone number</label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <input name="phoneNumber" value={values.phoneNumber} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.phoneNumber && errors.phoneNumber))} />
                                    <div className="invalid-feedback">{errors.phoneNumber}</div>
                                </div>

                                {/* Country */}
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="country">Country</label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <select
                                        name="countryIsoCode"
                                        className={getInputClassNames(!!(touched.countryIsoCode && errors.countryIsoCode))}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.countryIsoCode}>
                                        <option value="" label="Choose country..." />
                                        {!props.countries ? null : props.countries
                                            .map((country, i) => (<option
                                                key={country.iso}
                                                value={country.iso}
                                                label={country.name}
                                            />))
                                        }
                                    </select>
                                    <div className="invalid-feedback">{errors.countryIsoCode}</div>
                                </div>

                                {/* City */}
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="city">City</label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <input name="city" value={values.city} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.city && errors.city))} />
                                    <div className="invalid-feedback">{errors.city}</div>
                                </div>

                                {/* Postal Code */}
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="postalCode">Postal Code</label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <input name="postalCode" value={values.postalCode} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.postalCode && errors.postalCode))} />
                                    <div className="invalid-feedback">{errors.postalCode}</div>
                                </div>

                                {/* Address */}
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="address">Address</label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <input name="address" value={values.address} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.address && errors.address))} />
                                    <div className="invalid-feedback">{errors.address}</div>
                                </div>

                                {/* Save */}
                                <div className="form-group mb-0">
                                    <button
                                        type="submit"
                                        onClick={() => handleSubmit()}
                                        className="btn btn-primary"
                                        disabled={!dirty || !isValid || props.updateUserRequestState === RequestState.InProgress}>
                                        {props.updateUserRequestState === RequestState.InProgress ? "Saving changes" : "Save changes"}
                                    </button>
                                    <button
                                        onClick={() => props.history.goBack()}
                                        className="btn btn-primary ml-3">Cancel</button>
                                    {props.updateUserRequestState === RequestState.Failed && <p className="text-danger mt-3">Failed to save changes</p>}
                                    {props.updateUserRequestState === RequestState.Succeeded && <div className="valid-feedback d-block">User data successfully updated</div>}
                                </div>
                            </form>
                        }
                    </Formik>
                </div>
                <div className="col border-left">
                    {
                        (props.isAdmin || props.isOemAdmin) &&
                        <div className="form-group row">
                            <label htmlFor="privateLabel" className="col-sm-3 col-form-label">Private Label</label>
                            <div className="col-sm-5">
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control-plaintext"
                                    id="privateLabel"
                                    value={ props.privateLabels?.find(x => x.id === props.existingUser!.privateLabelId)?.name || "N/A" } />
                            </div>
                        </div>
                    }

                    {/*Role, if not own profile*/}
                    {!isOwnProfile() &&
                    <ChangeRoleForm
                        isAdmin={props.isAdmin}
                        isOemAdmin={props.isOemAdmin}
                        changeRole={(r: string) => props.changeRole(props.existingUser!.userId, r)}
                        changeRoleRequest={roleRequest}
                        isSubmitting={props.changeRoleRequestState === RequestState.InProgress}
                        isSuccessful={props.changeRoleRequestState === RequestState.Succeeded} />
                    }

                    {/* Username */}
                    {isOemUwg5 ?
                        <ChangeUsernameForm
                            isSuccessful={props.changeUsernameRequestState === RequestState.Succeeded}
                            isSubmitting={props.changeUsernameRequestState === RequestState.InProgress}
                            changeUsernameRequest={usernameRequest}
                            changeUsername={(username: string) => props.changeUsername(props.existingUser!.userId, username)} />
                        : null
                    }

                    {/* Email */}
                    <ChangeEmailForm
                        isEmailConfirmed={props.existingUser!.isEmailConfirmed}
                        isEmailChangeSuccessful={props.changeEmailRequestState === RequestState.Succeeded}
                        isEmailChangeSubmitting={props.changeEmailRequestState === RequestState.InProgress}
                        changeEmailRequest={emailRequest}
                        changeEmail={(email: string) => props.changeEmail(props.existingUser!.userId, email)}
                        isVerifyRequestSuccessful={props.verifyEmailRequestState === RequestState.Succeeded}
                        isVerifyRequestSubmitting={props.verifyEmailRequestState === RequestState.InProgress}
                        isVerifyRequestFailed={props.verifyEmailRequestState === RequestState.Failed}
                        initializeEmailVerification={() => props.initializeEmailVerification(props.match.params.userId)} />

                    {/* Password */}
                    {isOwnProfile() ?
                        editPasswordComponent()
                        :
                        <button
                            disabled={props.temporaryPasswordRequestState === RequestState.InProgress}
                            onClick={() => props.createTemporaryPassword(userId)}
                            className="btn btn-sm btn-warning mb-3">Generate Temporary Password</button>
                    }

                    {/* Temporary password error */}
                    {props.temporaryPasswordRequestState !== RequestState.Failed ? null : (
                        <p className="text-danger">Failed to create temporary password</p>
                    )}

                    {/* Temporary password popup */}
                    {props.temporaryPasswordRequestState !== RequestState.Succeeded ? null : confirmAlert({
                        customUI: ({ onClose }) => <ConfirmAlert
                            title={`Temporary password for ${props.existingUser!.firstName} ${props.existingUser!.lastName}`}
                            description={(<>
                                <p>
                                    The temporary password can be used to sign in as {props.existingUser!.firstName} {props.existingUser!.lastName}.
                                    <br />
                                    It can only be used <u>once</u> and it will expire in <u>10 minutes</u>
                                    , at {formatTime(addMinutes(new Date(), 10))}.
                                </p>
                                <table className="table table-sm">
                                    <tbody>
                                        <tr>
                                            { isOemUwg5 ?
                                                <th>Username</th>
                                                : <th>Email</th>
                                            }
                                            <td><code>{props.existingUser!.username}</code></td>
                                        </tr>
                                        <tr>
                                            <th>Temporary password</th>
                                            <td><code>{props.temporaryPassword}</code></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>)}
                            onConfirm={() => {
                                props.resetTemporaryPassword();
                                onClose();
                            }}
                            confirmText="Close" />
                    })}
                </div>
            </div>
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.editUser }),
    actionCreators
)(EditUser as any)));
