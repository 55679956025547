import React, { ComponentProps } from "react";
import { ReactComponent as NaLogo } from "./../../images/pl/OJ-NA/logo.svg";
import { ReactComponent as NaNnLogo } from "./../../images/pl/OJ-NA-NN/logo.svg";
import { ReactComponent as WyLogo } from "./../../images/pl/WY/logo.svg";
import { ReactComponent as NVentLogo } from "./../../images/pl/nVent/logo.svg";
import { ReactComponent as MapeiLogo } from "./../../images/pl/Mapei/logo.svg";

interface LogoSvgProps extends ComponentProps<"svg"> {
    plName: string;
}

export const LogoSvg = ({ plName, ...props }: LogoSvgProps) => {
    let Item: React.ComponentType;
    switch (plName) {
        case "OJ-NA": {
            Item = NaLogo;
            break;
        }
        case "OJ-NA-NN": {
            Item = NaNnLogo;
            break;
        }
        case "WY": {
            Item = WyLogo;
            break;
        }
        case "nVent": {
            Item = NVentLogo;
            break;
        }
        case "Mapei": {
            Item = MapeiLogo;
            break;
        }
        default: {
            Item = NaLogo;
            break;
        }
    }

    return <Item {...props} />;
};